import React from 'react'
import "./../styles/StaticFooter.css";

const StaticFooter = () => {
    return (
        <div className="static-footer">
        <p>Made with 💛 using React</p>
        </div>
    )
}

export default StaticFooter